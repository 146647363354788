import { getCurrentTenant, getCurrentUser } from 'utils/utils';
import intl from 'utils/intl';
import { getDvaApp } from 'utils/iocUtils';
import ClientMonitor from 'hops-agent-wa';

const getMenuDataByPath = (path) => {
  const state = getDvaApp()._store.getState();
  const { global: { tabs = [] } = {} } = state;
  return tabs.find((tab) => tab.path === path);
};

const pathNameMap = new Map(); // 缓存路径名字映射
const getMenuNameByPath = (path = location.pathname) => {
  if (!pathNameMap.get(path)) pathNameMap.set(path, getMenuDataByPath(path)?.title);
  return pathNameMap.get(path);
};

const getEventPath = (event) => {
  const path = event.path || (event.composedPath && event.composedPath());

  const { target } = event;

  if (path != null) {
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo = []) {
    const { parentNode } = node;

    if (!parentNode) {
      return memo;
    } else {
      return getParents(parentNode, memo.concat(parentNode));
    }
  }

  return [target].concat(getParents(target), window);
};

// 全局配置
export default {
  dvaAppInit: () => {
    const flag = `${process.env.HOPS_WA_FLAG}`;
    if (ClientMonitor && flag === 'true') {
      // 提前获取菜单编码，防止跳转后 tab 数据丢失
      ClientMonitor.addHandler('api', (msg) => {
        ClientMonitor.addCustomMsg({
          alg: 'avg',
          serviceId: msg.serviceId,
          category: 'API耗时',
          name: msg.url,
          event: 'api',
          location: msg.page,
          value: msg.time,
          tags: getMenuNameByPath() && [
            {
              key: 'menuName',
              value: intl.get(getMenuNameByPath()),
            },
          ],
        });
        return { ...msg, menu: getMenuNameByPath() };
      });

      ClientMonitor.addHandler('behavior', (msg, type, event) => {
        if (type === 'click') {
          const path = getEventPath(event);
          if (
            event.target.className.indexOf &&
            event.target.innerText &&
            (event.target.className.indexOf('content-menu') !== -1 ||
              event.target.className.indexOf('menu') !== -1)
          ) {
            ClientMonitor.addCustomMsg({
              alg: 'sum',
              serviceId: msg.serviceId,
              category: '菜单',
              name: event.target.innerText,
              event: type,
              location: msg.behavior.data.path,
              value: 1,
              tags: [],
            });
          } else if (
            event.target.nodeName === 'BUTTON' ||
            (path.filter((item) => item.nodeName === 'BUTTON').length &&
              path.filter((item) => item.nodeName === 'BUTTON')[0].innerText)
          ) {
            const menuNameByPath = getMenuNameByPath() || '';
            ClientMonitor.addCustomMsg({
              alg: 'sum',
              serviceId: msg.serviceId,
              category: `按钮`,
              name: `${path.filter((item) => item.nodeName === 'BUTTON')[0].innerText}`,
              event: type,
              location: menuNameByPath ? `${intl.get(menuNameByPath)} ${msg.page}` : `${msg.page}}`,
              value: 1,
              tags: [
                {
                  key: 'menuName',
                  value: menuNameByPath && intl.get(menuNameByPath),
                },
                {
                  key: 'path',
                  value: msg.behavior.data.path,
                },
              ],
            });
          }
        }
        return msg;
      });

      // 注册监听代码
      ClientMonitor.register({
        domain: `${process.env.HOPS_WA_API_HOST}`,
        serviceId: `${process.env.HOPS_WA_APP_UUID}`,
        service: `${process.env.HOPS_WA_APP_NAME}`,
        accessTokenUrl: `${process.env.HOPS_WA_ACCESSTOKENURL}`,
        namespace: `${process.env.HOPS_WA_NAMESPACE}`,
        clientId: `${process.env.HOPS_WA_CLIENTID}`,
        clientSecret: `${process.env.HOPS_WA_CLIENTSECRET}`,
        autoSendPv: true,
        isTrace: `${process.env.HOPS_WA_IS_TRACE}`,
        enableSPA: true,
        autoTracePerf: true,
        isAjax: true,
        isError: true,
        useFmp: true,
        isResource: true,
        isRequest: true,
        isResponse: true,
        isCustom: true,
        pushIntervalDuration: 30 * 1000,
        collector: `${process.env.HOPS_WA_COLLECTOR}`,
        // 上报数据处理函数
        recordHandler: (url, data) => {
          if ((data?.length ?? 0) <= 0) return data;

          // tag 添加描述信息
          if (url === `${process.env.HOPS_WA_COLLECTOR}`) {
            const tenantNum = getCurrentTenant()?.tenantNum;
            const userName = getCurrentUser()?.realName;
            const roleName = getCurrentUser()?.currentRoleName;

            return data.map((segment) => {
              const handledSegment = { ...segment };

              handledSegment.spans = handledSegment.spans.map((span) => {
                const handledSpan = { ...span, tags: [...span.tags] };
                handledSpan.tags.push({
                  key: 'menuName',
                  value:
                    getMenuNameByPath() &&
                    intl.get(getMenuNameByPath(handledSpan.operationName) || getMenuNameByPath()),
                });
                handledSpan.tags.push({ key: 'tenantNum', value: tenantNum });
                handledSpan.tags.push({ key: 'userName', value: userName });
                handledSpan.tags.push({
                  key: 'roleName',
                  value: roleName,
                });

                return handledSpan;
              });

              return handledSegment;
            });
          }

          const type = data[0].uploadType; // 数据类型
          // api 详情上报菜单名
          if (type === 'api') {
            return data.map((row) => ({
              ...row,
              menu:
                row.menu &&
                intl.get(row.menu || getMenuNameByPath(row.page) || getMenuNameByPath()),
            }));
          }

          return data;
        },
      });
    }
  },
};
